
import { defineComponent, ref } from 'vue'
import VerificationCode from '@/components/VerificationCode.vue'
import ResetPassword from '@/components/ResetPassword.vue'

export default defineComponent({
  components: { VerificationCode, ResetPassword },

  setup() {
    const email = ref('')

    const onReset = () => {
      email.value = ''
    }

    const onVerification = (emailAddr: string) => {
      email.value = emailAddr
    }

    return {
      onVerification,
      onReset,
      email
    }
  }
})
