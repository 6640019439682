import urlJoin from 'url-join'

const getSessionIdUrl = (baseUrl: string, sessionId: string) =>
  urlJoin(baseUrl, `?sessionId=${decodeURI(sessionId)}`)

function onFocus(ref: any) {
  setTimeout(() => {
    if (ref.value != null) {
      ref.value.focus()
    }
  }, 100)
}

export { getSessionIdUrl, onFocus }
