
import { defineComponent } from 'vue'
import { signOut } from '@/auth'
import { services } from '@/services'

export default defineComponent({
  beforeRouteEnter: async ({ query }) => {
    const signOutId =
      typeof query.signOutId === 'string' ? query.signOutId : undefined

    await signOut(services, signOutId)
  }
})
