export const ja = {
  'Sign In': 'サインイン',
  Email: 'メールアドレス',
  Password: 'パスワード',
  'Enter your password': 'パスワードを入力',
  'Enter your email': 'メールアドレスを入力',
  'Forgot password': 'パスワードを忘れた方',
  Next: '次へ',
  'Please enter your password': 'パスワードを入力して下さい',
  'Please enter your email address': 'メールアドレスを入力して下さい',
  'Incorrect username or password.': 'メールアドレスかパスワードが違います。',
  'Password attempts exceeded':
    'パスワード入力の上限に達しました。暫くしてからお試しください。',
  'Unkown error. Please retry later.':
    '不明なエラーが発生しました。暫くしてからお試しください。',
  'Forgot your password?': 'パスワードの再設定',
  'Please enter your email to reset your password. We are going to send a varification code to the email address.':
    'パスワードを変更するメールアドレスを入力してください。ご入力いただいたメールアドレスに認証コードを送ります。',
  'Reset your password': '新しいパスワードの設定',
  'Enter your verify code': '認証コードを入力してください。',
  'Enter your new password': '新しいパスワードを入力してください。',
  'Please must include:': 'パスワードには以下の文字を含めてください:',
  'At least 8 characters with no space': 'スペースを含まず8文字以上',
  'At least 1 upper case letter': '1文字以上の大文字',
  'At least 1 lower case letter': '1文字以上の小文字',
  'At least 1 number': '1文字以上の数字',
  'At least 1 of the following special charactors from {specialChars}':
    '{specialChars}の中から一文字以上',
  'Show your password': 'パスワードを表示',
  'Send a varification code': '認証コードを送信',
  'Reissu a varification code': '認証コードを再発行する',
  'Invalid password': '無効なパスワードです',
  'Invalid verification code provided, please try again.':
    '不正な認証コードです。',
  'Attempt limit exceeded, please try after some time.':
    '認証コード発行の上限に達しました。暫くたってから再度お試しください。',
  'Changed your password': 'パスワードを変更しました。',
  'Go to sign in': 'サインインへ',
  'You can use the new password when signin to our service.':
    'サインイン時に新しいパスワードをご利用することができます。',
  'Change your password': 'パスワード変更',
  'Enter your current password': '現在のパスワードを入力してください。',
  'Failed to change the password.': 'パスワードの変更に失敗しました。',
  Back: '戻る'
}
