<template>
  <div class="container mx-auto h-screen flex items-center ">
    <div class=" w-full">
      <div class="flex justify-center">
        <div class="w-96 md:border md:shadow-md bg-white p-4 md:p-8">
          <div class="flex items-center justify-items-center mb-4">
            <img src="@/assets/img/title.png" alt="image" class="mr-2" />
            <h1 class="text-gray-500 text-xl font-bold">
              {{ t('QUALITIA') }}
            </h1>
          </div>

          <h1 class="text-gray-700 text-2xl font-bold mb-4">
            {{ t('Reset your password') }}
          </h1>

          <input
            type="text"
            ref="refVerificationInput"
            :tabindex="tabindex"
            :disabled="inProgress"
            @keydown.enter="keyDown"
            class="px-1 border-b focus:outline-none border-gray-200 w-full mb-2"
            v-model="verifyCode"
            :placeholder="t('Enter your verify code')"
          />

          <p
            :class="[
              inProgress ? 'text-gray-500' : 'text-blue-500 cursor-pointer',
              ' text-xs mb-6 '
            ]"
            @click="onReset"
          >
            {{ t('Reissu a varification code') }} >>
          </p>

          <PasswordWithVerify
            :tabindex="tabindex + 1"
            :next="next"
            :value="password"
            :preVerify="verify"
            :back="onReset"
            @input="value => (password = value)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, getCurrentInstance, Ref } from 'vue'
import { onFocus } from '@/utils'
import Auth from '@aws-amplify/auth'
import { useTranslation } from '@/locales'
import { useRouter } from 'vue-router'
import PasswordWithVerify from '@/components/PasswordWithVerify.vue'

const specialLetters = `^$*.[]{}()?"!@#%&/\\,><':;|_~`

export default defineComponent({
  components: {
    PasswordWithVerify
  },
  props: {
    email: {
      type: String, // 本当は文字列の配列
      required: true
    }
  },
  emits: ['reset'],
  setup(props, { emit }) {
    const vm = getCurrentInstance()
    const { t } = useTranslation()
    const verifyCode = ref('')
    const password = ref('')
    const errorMsg = ref('')
    const inProgress = ref(false)
    const emailAddr = ref(props.email)
    const router = useRouter()
    const refVerificationInput = ref<HTMLElement | null>(null)
    const refPasswordInput = ref<HTMLElement | null>(null)
    const tabindex = ref(1)
    const emitter = vm!.appContext.config.globalProperties.emitter

    onMounted(() => {
      onFocus(refVerificationInput)
    })

    emitter.on('inProgress', (flag: boolean) => {
      inProgress.value = flag
    })

    const onReset = () => {
      if (!inProgress.value) {
        emit('reset')
      }
    }

    const next = (inputRef: Ref<HTMLElement | null>) =>
      new Promise<void>((resolve, reject) =>
        Auth.forgotPasswordSubmit(
          emailAddr.value,
          verifyCode.value,
          password.value
        )
          .then(() => {
            router.push('success')
            resolve()
          })
          .catch(err => {
            if (err.code === 'CodeMismatchException') {
              onFocus(refVerificationInput)
            } else {
              console.log(4444, inputRef)
              onFocus(inputRef)
            }
            reject(err.message)
          })
      )

    const verify = () => {
      if (verifyCode.value.length === 0) {
        return 'Enter your verify code'
      }
      return ''
    }

    const keyDown = async (event: any) => {
      if (event.keyCode === 13) {
        emitter.emit('keyDown', password)
      }
    }

    return {
      t,
      keyDown,
      onReset,
      next,
      verify,
      tabindex,
      refVerificationInput,
      refPasswordInput,
      inProgress,
      errorMsg,
      verifyCode,
      password,
      specialLetters
    }
  }
})
</script>
<style>
input:checked + div {
  @apply border-blue-500;
}
input:checked + div svg {
  @apply block;
}
</style>
