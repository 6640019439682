import urlJoin from 'url-join'

export function removeSessionId(url: string) {
  // urlの文字に何が使用されているのかちょっと自信がないので慎重にremoveする。
  const splitted = url.split('?')

  if (splitted.length === 1) return url

  if (splitted.length > 2) {
    throw Error(`dnsauth removeSessionId: can not handle ${url}`)
  }

  //TODO: 正規表現がよう分からんのでとりあえずこれでいくがもっとよい方法探す。
  const removed = splitted[1]
    .split('&')
    .filter(q => !q.startsWith('sessionId'))
    .join('&')

  if (removed.length === 0) {
    return splitted[0]
  }

  return `${splitted[0]}?${removed}`
}

export function redirectToSignIn(subDir?: string) {
  location.replace(
    urlJoin(
      `${process.env.VUE_APP_DOMAIN}${subDir == null ? '' : subDir}`,
      `?redirect=${encodeURIComponent(removeSessionId(location.href))}`
    )
  )
}
