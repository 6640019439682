import { createApp } from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import '@/assets/styles/index.scss'
import router from '@/router'
import i18n from '@/plugins/i18n'
import mitt from 'mitt'
const emitter = mitt()

const app = createApp(App)
app.config.globalProperties.emitter = emitter
app
  .use(router)
  .use(i18n)
  .mount('#app')
