import { axios, getAuthorization } from '@/api/axios'

type AuthInfo = {
  userName: string
  idToken: string
  accessToken: string
  refreshToken: string
  userPoolId: string
  userPoolWebClientId: string
}

export const post = (authInfo?: AuthInfo): Promise<{ sessionId: string }> => {
  return new Promise<{ sessionId: string }>((resolve, reject) => {
    axios
      .post<{ sessionId: string }>(`token`, authInfo, {
        headers: getAuthorization()
      })
      .then(result => {
        resolve(result.data)
      })
      .catch((err: any) => {
        reject(err)
      })
  })
}
