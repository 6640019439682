import axios from 'axios'
import axiosRetry from 'axios-retry'
import { getToken } from '@/auth/token'

axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL

axiosRetry(axios, { retries: 3 })

function getAuthorization() {
  const token = getToken()
  return {
    Authorization: `Bearer ${token}`
  }
}

export { axios, getAuthorization }
