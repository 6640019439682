
import {
  defineComponent,
  ref,
  onMounted,
  getCurrentInstance,
  Ref,
  onBeforeMount
} from 'vue'
import { onFocus } from '@/utils'
import Auth from '@aws-amplify/auth'
import { useTranslation } from '@/locales'
import { useRoute } from 'vue-router'
import ShowPassword from '@/components/ShowPassword.vue'
import PasswordWithVerify from '@/components/PasswordWithVerify.vue'
import { refreshToken, refreshTokenWithRedirect } from '@/auth/token'
import { redirectToSignIn } from '@/auth/redirect'

export default defineComponent({
  components: {
    ShowPassword,
    PasswordWithVerify
  },
  beforeRouteEnter: async ({ query, path }, __, next) => {
    const res = await refreshToken()

    if (res == null) {
      redirectToSignIn()
      return
    }

    if (
      typeof query.sessionId === 'string' ||
      typeof query.sessionId === 'undefined'
    ) {
      if (typeof query.sessionId === 'undefined') {
        next()
      } else {
        next({
          path,
          query: {
            ...query,
            sessionId: undefined
          }
        })
      }
    }
  },
  setup() {
    const vm = getCurrentInstance()

    const { t } = useTranslation()
    const password = ref('')
    const newPassword = ref('')
    const inProgress = ref(false)
    const showSuccess = ref(false)
    const showPassword = ref(false)
    const refPasswordInput = ref<HTMLElement | null>(null)
    const route = useRoute()
    const emitter = vm!.appContext.config.globalProperties.emitter
    const tabindex = ref(1)
    const redirectQuery = ref(route.query.redirect)

    onMounted(() => {
      onFocus(refPasswordInput)
    })

    emitter.on('inProgress', (flag: boolean) => {
      inProgress.value = flag
    })

    const onShowPassword = () => (showPassword.value = !showPassword.value)

    const verify = () => {
      if (password.value.length === 0) {
        onFocus(refPasswordInput)
        return 'Please enter your password'
      }
      return ''
    }

    const next = (refNewPassword: Ref<HTMLElement | null>) =>
      new Promise<void>((resolve, reject) =>
        refreshTokenWithRedirect()
          .then(() => {
            Auth.currentAuthenticatedUser()
              .then(user => {
                Auth.changePassword(user, password.value, newPassword.value)
                  .then(() => {
                    showSuccess.value = true
                    resolve()
                  })
                  .catch(err => {
                    if (err.code === 'InvalidParameterException') {
                      reject('Failed to change the password.')
                    } else {
                      reject('Unkown error. Please retry later.')
                    }
                    onFocus(refNewPassword)
                  })
              })
              .catch(() => {
                reject('Not found the user. You need to login first.')
              })
          })
          .catch(() => reject())
      )

    const keyDown = (event: any) => {
      if (event.keyCode === 13) {
        emitter.emit('keyDown', password)
      }
    }

    const redirectTo = () => {
      const { redirect } = route.query

      if (redirect != null && typeof redirect === 'string') {
        location.replace(decodeURIComponent(redirect))
      } else {
        location.replace('/')
      }
    }

    return {
      t,
      keyDown,
      onShowPassword,
      redirectTo,
      next,
      verify,
      redirectQuery,
      showSuccess,
      tabindex,
      refPasswordInput,
      showPassword,
      inProgress,
      password,
      newPassword
    }
  }
})
