import { axios, getAuthorization } from '@/api/axios'

export const get = (signOutId?: string): Promise<boolean> => {
  const query = `signOutId=${signOutId}`

  return new Promise<boolean>((resolve, reject) => {
    axios
      .get<boolean>(`signout?${query}`, {
        headers: getAuthorization()
      })
      .then(res => {
        console.log(res)
        resolve(res.data)
      })
      .catch(err => {
        reject(false)
      })
  })
}
