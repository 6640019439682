
import { defineComponent, ref, onMounted } from 'vue'
import { onFocus } from '@/utils'
import Auth from '@aws-amplify/auth'
import { useTranslation } from '@/locales'

export default defineComponent({
  emits: ['verfication'],
  setup(_, { emit }) {
    const { t } = useTranslation()
    const email = ref('')
    const errorMsg = ref('')
    const inProgress = ref(false)
    const refEmailInput = ref<HTMLElement | null>(null)

    onMounted(() => {
      onFocus(refEmailInput)
    })

    const onNext = async () => {
      if (inProgress.value) {
        return
      }

      inProgress.value = true
      errorMsg.value = ''

      if (email.value.length === 0) {
        errorMsg.value = t('Please enter your email address')
        onFocus(refEmailInput)
      }

      if (errorMsg.value.length > 0) {
        inProgress.value = false
      }

      await Auth.forgotPassword(email.value)
        .then(() => {
          emit('verfication', email.value)
        })
        .catch(err => {
          if (err.code === 'LimitExceededException') {
            errorMsg.value = t(err.message)
          } else {
            errorMsg.value = err.message
          }
          onFocus(refEmailInput)
          inProgress.value = false
        })
    }

    const keyDown = async (event: any) => {
      if (event.keyCode === 13) {
        onNext()
      }
    }

    return {
      t,
      keyDown,
      onNext,
      refEmailInput,
      inProgress,
      errorMsg,
      email
    }
  }
})
