
import { defineComponent } from 'vue'
import { useTranslation } from '@/locales'

export default defineComponent({
  emits: ['showPassword'],
  props: {
    inProgress: {
      type: Boolean,
      required: true,
    }
  },
  setup(_, { emit }) {
    const { t } = useTranslation()
    const onShowPassword = () => emit('showPassword')

    return {
      t,
      onShowPassword
    }
  }
})
