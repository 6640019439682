
import { defineComponent, ref, onMounted } from 'vue'
import { useTranslation } from '@/locales'
import { useRouter } from 'vue-router'
import { onFocus } from '@/utils'

export default defineComponent({
  setup() {
    const { t } = useTranslation()
    const refBtn = ref(null)
    const inProgress = ref(false)
    const router = useRouter()

    onMounted(() => {
      onFocus(refBtn)
    })

    const onNext = async () => {
      if (inProgress.value) {
        return
      }

      inProgress.value = true

      router.push('/')

      inProgress.value = false
    }

    const keyDown = async (event: any) => {
      if (event.keyCode === 13) {
        onNext()
      }
    }

    return {
      t,
      keyDown,
      onNext,
      refBtn,
      inProgress
    }
  }
})
