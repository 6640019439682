<template>
  <VerificationCode v-if="email.length === 0" @verfication="onVerification" />
  <ResetPassword v-else @reset="onReset" :email="email" />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import VerificationCode from '@/components/VerificationCode.vue'
import ResetPassword from '@/components/ResetPassword.vue'

export default defineComponent({
  components: { VerificationCode, ResetPassword },

  setup() {
    const email = ref('')

    const onReset = () => {
      email.value = ''
    }

    const onVerification = (emailAddr: string) => {
      email.value = emailAddr
    }

    return {
      onVerification,
      onReset,
      email
    }
  }
})
</script>
