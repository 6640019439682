<template>
  <div class="container mx-auto h-screen flex items-center ">
    <div class=" w-full">
      <div class="flex justify-center">
        <div class="w-96 h-96 md:border md:shadow-md bg-white p-4 md:p-8">
          <div class="flex items-center justify-items-center mb-4">
            <img src="@/assets/img/title.png" alt="image" class="mr-2" />
            <h1 class="text-gray-500 text-xl font-bold">
              {{ t('QUALITIA') }}
            </h1>
          </div>
          <h1 class="text-gray-700 text-2xl font-bold mb-4">
            {{ t('Forgot your password?') }}
          </h1>
          <p class="text-gray-400 text-sm mb-8">
            {{
              t(
                'Please enter your email to reset your password. We are going to send a varification code to the email address.'
              )
            }}
          </p>

          <input
            class="px-1 border-b focus:outline-none border-gray-200 w-full mb-8"
            v-model="email"
            ref="refEmailInput"
            @keydown.enter="keyDown"
            :disabled="inProgress"
            :placeholder="t('Enter your email')"
          />

          <p
            :class="[
              errorMsg.length === 0 ? 'invisible' : '',
              'h-4 text-red-600 text-xs mb-2'
            ]"
          >
            {{ errorMsg }}
          </p>

          <div class="w-full flex justify-end">
            <button
              :class="[
                inProgress ? 'bg-blue-200' : 'bg-blue-500',
                'px-2 py-1  text-white w-32'
              ]"
              @click="onNext"
            >
              {{ t('Send a varification code') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { onFocus } from '@/utils'
import Auth from '@aws-amplify/auth'
import { useTranslation } from '@/locales'

export default defineComponent({
  emits: ['verfication'],
  setup(_, { emit }) {
    const { t } = useTranslation()
    const email = ref('')
    const errorMsg = ref('')
    const inProgress = ref(false)
    const refEmailInput = ref<HTMLElement | null>(null)

    onMounted(() => {
      onFocus(refEmailInput)
    })

    const onNext = async () => {
      if (inProgress.value) {
        return
      }

      inProgress.value = true
      errorMsg.value = ''

      if (email.value.length === 0) {
        errorMsg.value = t('Please enter your email address')
        onFocus(refEmailInput)
      }

      if (errorMsg.value.length > 0) {
        inProgress.value = false
      }

      await Auth.forgotPassword(email.value)
        .then(() => {
          emit('verfication', email.value)
        })
        .catch(err => {
          if (err.code === 'LimitExceededException') {
            errorMsg.value = t(err.message)
          } else {
            errorMsg.value = err.message
          }
          onFocus(refEmailInput)
          inProgress.value = false
        })
    }

    const keyDown = async (event: any) => {
      if (event.keyCode === 13) {
        onNext()
      }
    }

    return {
      t,
      keyDown,
      onNext,
      refEmailInput,
      inProgress,
      errorMsg,
      email
    }
  }
})
</script>
