<template>
  <div class="container mx-auto h-screen flex items-center ">
    <div class=" w-full">
      <div class="flex justify-center">
        <div class="w-96 md:border md:shadow-md bg-white p-4 md:p-8">
          <div class="flex items-center justify-items-center mb-4">
            <img src="@/assets/img/title.png" alt="image" class="mr-2" />
            <h1 class="text-gray-500 text-xl font-bold">
              {{ t('QUALITIA') }}
            </h1>
          </div>

          <h1 class="text-gray-700 text-2xl font-bold mb-4">
            {{ t('Changed your password') }}
          </h1>
          <p class="text-gray-400 text-sm mb-8">
            {{ t('You can use the new password when signin to our service.') }}
          </p>
          <div class="w-full flex justify-end">
            <button
              ref="refBtn"
              @keydown="keyDown"
              :class="[
                inProgress ? 'bg-blue-200' : 'bg-blue-500',
                'px-2 py-1  text-white'
              ]"
              @click="onNext"
            >
              {{ t('Go to sign in') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { useTranslation } from '@/locales'
import { useRouter } from 'vue-router'
import { onFocus } from '@/utils'

export default defineComponent({
  setup() {
    const { t } = useTranslation()
    const refBtn = ref(null)
    const inProgress = ref(false)
    const router = useRouter()

    onMounted(() => {
      onFocus(refBtn)
    })

    const onNext = async () => {
      if (inProgress.value) {
        return
      }

      inProgress.value = true

      router.push('/')

      inProgress.value = false
    }

    const keyDown = async (event: any) => {
      if (event.keyCode === 13) {
        onNext()
      }
    }

    return {
      t,
      keyDown,
      onNext,
      refBtn,
      inProgress
    }
  }
})
</script>
