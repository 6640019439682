
import { defineComponent, ref, onMounted, getCurrentInstance, Ref } from 'vue'
import { onFocus } from '@/utils'
import Auth from '@aws-amplify/auth'
import { useTranslation } from '@/locales'
import { useRouter } from 'vue-router'
import PasswordWithVerify from '@/components/PasswordWithVerify.vue'

const specialLetters = `^$*.[]{}()?"!@#%&/\\,><':;|_~`

export default defineComponent({
  components: {
    PasswordWithVerify
  },
  props: {
    email: {
      type: String, // 本当は文字列の配列
      required: true
    }
  },
  emits: ['reset'],
  setup(props, { emit }) {
    const vm = getCurrentInstance()
    const { t } = useTranslation()
    const verifyCode = ref('')
    const password = ref('')
    const errorMsg = ref('')
    const inProgress = ref(false)
    const emailAddr = ref(props.email)
    const router = useRouter()
    const refVerificationInput = ref<HTMLElement | null>(null)
    const refPasswordInput = ref<HTMLElement | null>(null)
    const tabindex = ref(1)
    const emitter = vm!.appContext.config.globalProperties.emitter

    onMounted(() => {
      onFocus(refVerificationInput)
    })

    emitter.on('inProgress', (flag: boolean) => {
      inProgress.value = flag
    })

    const onReset = () => {
      if (!inProgress.value) {
        emit('reset')
      }
    }

    const next = (inputRef: Ref<HTMLElement | null>) =>
      new Promise<void>((resolve, reject) =>
        Auth.forgotPasswordSubmit(
          emailAddr.value,
          verifyCode.value,
          password.value
        )
          .then(() => {
            router.push('success')
            resolve()
          })
          .catch(err => {
            if (err.code === 'CodeMismatchException') {
              onFocus(refVerificationInput)
            } else {
              console.log(4444, inputRef)
              onFocus(inputRef)
            }
            reject(err.message)
          })
      )

    const verify = () => {
      if (verifyCode.value.length === 0) {
        return 'Enter your verify code'
      }
      return ''
    }

    const keyDown = async (event: any) => {
      if (event.keyCode === 13) {
        emitter.emit('keyDown', password)
      }
    }

    return {
      t,
      keyDown,
      onReset,
      next,
      verify,
      tabindex,
      refVerificationInput,
      refPasswordInput,
      inProgress,
      errorMsg,
      verifyCode,
      password,
      specialLetters
    }
  }
})
