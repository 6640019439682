<template>
  <div class="container mx-auto h-screen flex items-center ">
    <div class=" w-full">
      <div v-if="!showSuccess" class="flex justify-center">
        <div class="w-96 md:border md:shadow-md bg-white p-4 md:p-8">
          <div class="flex items-center justify-items-center mb-4">
            <img src="@/assets/img/title.png" alt="image" class="mr-2" />
            <h1 class="text-gray-500 text-xl font-bold">
              {{ t('QUALITIA') }}
            </h1>
          </div>

          <h1 class="text-gray-700 text-2xl font-bold mb-4">
            {{ t('Change your password') }}
          </h1>

          <input
            :tabindex="tabindex"
            :type="showPassword ? 'text' : 'password'"
            ref="refPasswordInput"
            :disabled="inProgress"
            @keydown.enter="keyDown"
            v-model="password"
            class="px-1 border-b focus:outline-none border-gray-200 w-full mb-2"
            :placeholder="t('Enter your current password')"
          />

          <div class="mb-4">
            <ShowPassword
              @showPassword="onShowPassword"
              :inProgress="inProgress"
            />
          </div>

          <PasswordWithVerify
            :tabindex="tabindex + 1"
            :next="next"
            :value="newPassword"
            :preVerify="verify"
            :placeholder="'Enter your new password'"
            :back="redirectQuery != null ? redirectTo : undefined"
            @input="value => (newPassword = value)"
          />
        </div>
      </div>
      <div v-else class="flex justify-center">
        <div class="w-96 md:border md:shadow-md bg-white p-4 md:p-8">
          <div class="flex items-center justify-items-center mb-4">
            <img src="@/assets/img/title.png" alt="image" class="mr-2" />
            <h1 class="text-gray-500 text-xl font-bold">
              {{ t('QUALITIA') }}
            </h1>
          </div>
          <h1 className="text-3xl font-bold mb-4">
            {{ t('Changed your password') }}
          </h1>
          <p class="text-gray-400 text-base mb-8">
            {{ t('You can use the new password when signin to our service.') }}
          </p>
          <div class="w-full flex items-center justify-end">
            <button
              className="bg-blue-500 px-8 text-white"
              autofocus
              @click="redirectTo"
            >
              {{ t('Back') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  getCurrentInstance,
  Ref,
  onBeforeMount
} from 'vue'
import { onFocus } from '@/utils'
import Auth from '@aws-amplify/auth'
import { useTranslation } from '@/locales'
import { useRoute } from 'vue-router'
import ShowPassword from '@/components/ShowPassword.vue'
import PasswordWithVerify from '@/components/PasswordWithVerify.vue'
import { refreshToken, refreshTokenWithRedirect } from '@/auth/token'
import { redirectToSignIn } from '@/auth/redirect'

export default defineComponent({
  components: {
    ShowPassword,
    PasswordWithVerify
  },
  beforeRouteEnter: async ({ query, path }, __, next) => {
    const res = await refreshToken()

    if (res == null) {
      redirectToSignIn()
      return
    }

    if (
      typeof query.sessionId === 'string' ||
      typeof query.sessionId === 'undefined'
    ) {
      if (typeof query.sessionId === 'undefined') {
        next()
      } else {
        next({
          path,
          query: {
            ...query,
            sessionId: undefined
          }
        })
      }
    }
  },
  setup() {
    const vm = getCurrentInstance()

    const { t } = useTranslation()
    const password = ref('')
    const newPassword = ref('')
    const inProgress = ref(false)
    const showSuccess = ref(false)
    const showPassword = ref(false)
    const refPasswordInput = ref<HTMLElement | null>(null)
    const route = useRoute()
    const emitter = vm!.appContext.config.globalProperties.emitter
    const tabindex = ref(1)
    const redirectQuery = ref(route.query.redirect)

    onMounted(() => {
      onFocus(refPasswordInput)
    })

    emitter.on('inProgress', (flag: boolean) => {
      inProgress.value = flag
    })

    const onShowPassword = () => (showPassword.value = !showPassword.value)

    const verify = () => {
      if (password.value.length === 0) {
        onFocus(refPasswordInput)
        return 'Please enter your password'
      }
      return ''
    }

    const next = (refNewPassword: Ref<HTMLElement | null>) =>
      new Promise<void>((resolve, reject) =>
        refreshTokenWithRedirect()
          .then(() => {
            Auth.currentAuthenticatedUser()
              .then(user => {
                Auth.changePassword(user, password.value, newPassword.value)
                  .then(() => {
                    showSuccess.value = true
                    resolve()
                  })
                  .catch(err => {
                    if (err.code === 'InvalidParameterException') {
                      reject('Failed to change the password.')
                    } else {
                      reject('Unkown error. Please retry later.')
                    }
                    onFocus(refNewPassword)
                  })
              })
              .catch(() => {
                reject('Not found the user. You need to login first.')
              })
          })
          .catch(() => reject())
      )

    const keyDown = (event: any) => {
      if (event.keyCode === 13) {
        emitter.emit('keyDown', password)
      }
    }

    const redirectTo = () => {
      const { redirect } = route.query

      if (redirect != null && typeof redirect === 'string') {
        location.replace(decodeURIComponent(redirect))
      } else {
        location.replace('/')
      }
    }

    return {
      t,
      keyDown,
      onShowPassword,
      redirectTo,
      next,
      verify,
      redirectQuery,
      showSuccess,
      tabindex,
      refPasswordInput,
      showPassword,
      inProgress,
      password,
      newPassword
    }
  }
})
</script>
