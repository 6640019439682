import Auth from '@aws-amplify/auth'
import { redirectToSignIn } from '@/auth/redirect'

let _jwtToken: null | string = null

export async function refreshToken() {
  // Check and refresh token if need
  const res = await Auth.currentUserInfo()

  if (res == null || Object.keys(res).length === 0) {
    return undefined
  }

  const user = await Auth.currentAuthenticatedUser().catch(() => undefined)

  if (user == null) {
    return undefined
  }

  _jwtToken = user.signInUserSession.idToken.jwtToken

  return _jwtToken
}

export function refreshTokenWithRedirect() {
  return new Promise<void>((resolve, reject) =>
    Auth.currentUserInfo()
      .then(res => {
        if (res == null || Object.keys(res).length === 0) {
          redirectToSignIn()
          reject()
          return
        }

        Auth.currentAuthenticatedUser()
          .then(user => {
            _jwtToken = user.signInUserSession.idToken.jwtToken
            resolve()
          })
          .catch(() => {
            redirectToSignIn()
            reject()
          })
      })
      .catch(() => {
        redirectToSignIn()
        reject()
      })
  )
}

export function getToken() {
  return _jwtToken
}
