<template>
  <input
    :type="showPassword ? 'text' : 'password'"
    :tabindex="tabindex"
    ref="refPasswordInput"
    :disabled="inProgress"
    @keydown.enter="keyDown"
    class="px-1 border-b focus:outline-none border-gray-200 w-full mb-2"
    :value="value"
    @input="handleInput"
    :placeholder="t(placeholder)"
  />

  <div class="mb-4">
    <ShowPassword @showPassword="onShowPassword" :inProgress="inProgress" />
  </div>

  <div class="text-xs">
    <h2 class="text-gray-600 mb-1">{{ t('Please must include:') }}</h2>
    <p
      v-for="(require, index) in required"
      :class="[require.isFalse ? 'text-red-600' : 'text-gray-600', '']"
      :key="index"
    >
      {{ require.description }}
    </p>
  </div>

  <p
    :class="[
      errorMsg.length === 0 ? 'invisible' : '',
      'h-4 text-red-600 text-xs mb-2 mt-2'
    ]"
  >
    {{ errorMsg }}
  </p>

  <div class="w-full flex justify-end">
    <button
      v-if="back != null"
      :tabindex="tabindex + 2"
      :class="[
        inProgress ? 'bg-blue-200' : 'bg-blue-500',
        'px-2 py-1  text-white w-32 mr-2'
      ]"
      @click="() => { if(back != null) back() }"
    >
      {{ t('Back') }}
    </button>

    <button
      :tabindex="tabindex + 1"
      :class="[
        inProgress ? 'bg-blue-200' : 'bg-blue-500',
        'px-2 py-1  text-white w-32'
      ]"
      @click="onNext"
    >
      {{ t('Next') }}
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, getCurrentInstance } from 'vue'
import { onFocus } from '@/utils'
import { useTranslation } from '@/locales'
import ShowPassword from '@/components/ShowPassword.vue'

type Required = {
  isFalse: boolean
  check: (value: string) => boolean
  description: string
}

const specialLetters = `^$*.[]{}()?"!@#%&/\\,><':;|_~`

export default defineComponent({
  components: {
    ShowPassword
  },
  props: {
    preVerify: {
      type: Function, // 本当は文字列の配列
      required: false
    },
    next: {
      type: Function, // 本当は文字列の配列
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    tabindex: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      default: 'Enter your password'
    },
    back: {
      type: Function,
      required: false
    }
  },
  emits: ['input'],
  setup(props, { emit }) {
    const { t } = useTranslation()
    const password = ref('')
    const vm = getCurrentInstance()

    const errorMsg = ref('')
    const inProgress = ref(false)
    const showSuccess = ref(false)
    const showPassword = ref(false)
    const refPasswordInput = ref<HTMLElement | null>(null)

    const emitter = vm!.appContext.config.globalProperties.emitter

    const required = reactive<Required[]>([
      {
        isFalse: false,
        check: (value: string) =>
          !(value.length < 8 || value.indexOf(' ') !== -1),
        description: t('At least 8 characters with no space')
      },
      {
        isFalse: false,
        check: (value: string) => {
          const upperCase = /[A-Z]{1,}/
          return upperCase.test(value)
        },
        description: t('At least 1 upper case letter')
      },
      {
        isFalse: false,
        check: (value: string) => {
          const lowerCase = /[a-z]{1,}/
          return lowerCase.test(value)
        },
        description: t('At least 1 lower case letter')
      },
      {
        isFalse: false,
        check: (value: string) => {
          const number = /[0-9]{1,}/
          return number.test(value)
        },
        description: t('At least 1 number')
      },
      {
        isFalse: false,
        check: (value: string) => {
          const special = /(?=.*?[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`])/
          return special.test(value)
        },
        description: t(
          'At least 1 of the following special charactors from {specialChars}',
          { specialChars: specialLetters }
        )
      }
    ])

    const handleInput = (e: Event) => {
      const target = e.target as HTMLInputElement
      emit('input', target.value)
    }

    const onShowPassword = () => (showPassword.value = !showPassword.value)

    const checkPassword = () => {
      errorMsg.value = ''
      required.forEach(require => {
        require.isFalse = !require.check(props.value)
        if (require.isFalse) {
          errorMsg.value = t('Invalid password')
        }
      })

      if (errorMsg.value.length > 0) {
        onFocus(refPasswordInput)
        return true
      }

      return false
    }

    const onProgress = (flag: boolean) => {
      emitter.emit('inProgress', flag)
      inProgress.value = flag
    }

    const onNext = () => {
      if (inProgress.value) {
        return
      }

      onProgress(true)
      errorMsg.value = ''

      if (props.preVerify != null) {
        errorMsg.value = t(props.preVerify())
        if (errorMsg.value.length > 0) {
          onProgress(false)
          return
        }
      }

      if (checkPassword()) {
        onProgress(false)
        return
      }

      props
        .next(refPasswordInput)
        .then(() => {
          errorMsg.value = ''
          onProgress(false)
        })
        .catch((err?: string) => {
          errorMsg.value = t(err == null ? '' : err)
          onProgress(false)
        })
    }

    emitter.on('keyDown', () => {
      onNext()
    })

    const keyDown = async (event: any) => {
      if (event.keyCode === 13) {
        onNext()
      }
    }

    return {
      t,
      keyDown,
      onShowPassword,
      onNext,
      handleInput,
      showSuccess,
      required,
      refPasswordInput,
      showPassword,
      inProgress,
      errorMsg,
      password,
      specialLetters
    }
  }
})
</script>
